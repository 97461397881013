import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { concatMap, finalize, forkJoin, Observable, of, Subject, switchMap, tap } from 'rxjs';

import {
  AudioPreview,
  EnrichedExercise,
  ExerciseEditBody,
  ExplanationVideo,
  ImagesFormat,
  Language,
  S3FileContent,
  ShortLanguage,
  TableRow,
  VideoImage,
} from '@models';
import { BackEndpoints, generatePaymentBackofficeEndpoint } from '@utils';

import { S3Service } from './s3.service';


@Injectable()
export class ExercisesEditService {

  private readonly exerciseToEdit$: Subject<EnrichedExercise | null> = new Subject();
  get exerciseToEdit(): Subject<EnrichedExercise | null> {
    return this.exerciseToEdit$;
  }

  private selectedLanguage!: ShortLanguage;
  set language(lang: Language) {
    this.selectedLanguage = ShortLanguage[lang];
  }

  private exerciseToUpdate!: EnrichedExercise;
  private exerciseEditBody!: ExerciseEditBody;

  public set exerciseObject(exercise: EnrichedExercise) {
    this.exerciseToUpdate = exercise;
  }

  public videoTableRows!: TableRow[];
  public audioTableRows!: TableRow[];


  constructor(
    private readonly http: HttpClient,
    private readonly s3Service: S3Service,
  ) { }

  private mapGenericInfoVideosRepetition(): void {
    this.videoTableRows.forEach(row => {
      const repetition = row[0].input?.control.value;
      const videoId = row[1].text;

      this.exerciseToUpdate.genericInformation.explanationVideo?.forEach(video => {
        if (video.VideoId === videoId) {
          video.Repetition = repetition ? +repetition : 0;
        }
      });
    });
  }

  private mapAudioListRepetition(): void {
    this.audioTableRows.forEach(row => {
      const repetition = row[0].input?.control.value;
      const audioId = row[1].text;

      this.exerciseToUpdate.languageInformation.audio?.forEach(audio => {
        if (audio.AudioID === audioId) {
          audio.Repetition = repetition ? +repetition : 0;
        }
      });
    });
  }

  private getExerciseEditBody(): ExerciseEditBody {
    const exercise = this.exerciseToUpdate;

    return {
      VideosExercise: exercise.genericInformation.explanationVideo,
      ImagesExercise: exercise.genericInformation.image,
      VideoPreviewId: exercise.genericInformation.videoPreview,
      ImagePreview: exercise.genericInformation.videoImage,
      AudioPreview: exercise.languageInformation.audioPreview,
      AudiosExercise: exercise.languageInformation.audio,
      EnrichedExerciseDetailByLanguage: [
        {
          Language: this.selectedLanguage,
          Name: exercise.languageInformation.name,
          Description: exercise.languageInformation.description,
          AudioPreview: exercise.languageInformation.audioPreview,
          AudiosExercise: exercise.languageInformation.audio,
        },
      ],
      Environment: exercise.environment,
      ExerciseId: exercise.code,
      ExerciseType: 0,
      ProgramId: exercise.programId,
    };
  }

  private removeUploadedObject(object: VideoImage | AudioPreview): void {
    delete object?.file;
    delete object?.changed;
  }


  /** Methods to upload files */

  /**
   * All the upload methods bellow (uploadImagesExercise, uploadImagePreview, uploadAudioExercise
   * and uploadAudioPreview) has an unknown observable because the `uploadFile` methods returns void.
   * but if we change the type to Observable<void> the request is cancelled in the browser. So, it
   * is typed with unknown once we dont use the return of the `uploadFile` method, we just use the
   * status code of the request.
   */
  private uploadImagesExercise(): Observable<unknown>[] {
    const uploadList$: Observable<unknown>[] = [];

    this.exerciseEditBody.ImagesExercise[0].Image.forEach(image => {
      if (image.changed) {
        const obs$ = this.s3Service.getPresignedUrl(image.Src).pipe(
          tap(response => image.Src = response.newFileName),
          switchMap(response => this.s3Service.uploadFile(response.url, image.file as File)),
          finalize(() => this.removeUploadedObject(image)),
        );

        uploadList$.push(obs$);
      }
    });

    return uploadList$;
  }

  private uploadImagePreview(): Observable<unknown>[] {
    const uploadList$: Observable<unknown>[] = [];

    this.exerciseEditBody.ImagePreview.forEach(image => {
      if (image.changed) {
        const obs$ = this.s3Service.getPresignedUrl(image.Src).pipe(
          tap(response => image.Src = response.newFileName),
          switchMap(response => this.s3Service.uploadFile(response.url, image.file as File)),
          finalize(() => this.removeUploadedObject(image)),
        );

        uploadList$.push(obs$);
      }
    });

    return uploadList$;
  }

  private uploadAudioExercise(): Observable<unknown>[] {
    const uploadList$: Observable<unknown>[] = [];

    this.exerciseEditBody.AudiosExercise?.forEach(audio => {
      if (audio.changed) {
        const obs$ = this.s3Service.getPresignedUrl(audio.AudioID, S3FileContent.MP3).pipe(
          tap(response => audio.AudioID = response.newFileName),
          switchMap(response => this.s3Service.uploadFile(response.url, audio.file as File)),
          finalize(() => this.removeUploadedObject(audio)),
        );

        uploadList$.push(obs$);
      }
    });

    return uploadList$;
  }

  private uploadAudioPreview(): Observable<unknown> {
    if (this.exerciseEditBody.AudioPreview?.changed) {
      const file = this.exerciseEditBody.AudioPreview.file as File;

      this.removeUploadedObject(this.exerciseEditBody.AudioPreview as AudioPreview);

      return this.s3Service.getPresignedUrl(this.exerciseEditBody.AudioPreview.AudioID, S3FileContent.MP3).pipe(
        tap(response => {
          const newAudioPreview: AudioPreview = {
            AudioID: response.newFileName,
            Description: this.exerciseEditBody.AudioPreview?.Description as string,
            Repetition: 0,
          };

          this.exerciseEditBody.AudioPreview = newAudioPreview;
          this.exerciseEditBody.EnrichedExerciseDetailByLanguage[0].AudioPreview = newAudioPreview;
        }),
        switchMap(response => this.s3Service.uploadFile(response.url, file)),
      );
    }

    return of(null);
  }

  /** Methods to change the exercise object body. */

  public changeExerciseImage(fileName: string, format: ImagesFormat, file: File): void {
    /** If this format doesnt exist */
    if (!this.exerciseToUpdate.genericInformation.image[0].Image.some(image => image.ChannelType === format)) {
      this.exerciseToUpdate.genericInformation.image[0].Image.push({
        Src: fileName,
        ChannelType: format,
        changed: true,
        file,
      });

      return;
    }

    this.exerciseToUpdate.genericInformation.image[0].Image.forEach(image => {
      if (image.ChannelType === format) {
        image.Src = fileName;
        image.changed = true;
        image.file = file;
      }
    });
  }

  public changeVideoImage(fileName: string, format: ImagesFormat, file: File): void {
    /** If this format doesnt exist */
    if (!this.exerciseToUpdate.genericInformation.videoImage.some(image => image.ChannelType === format)) {
      this.exerciseToUpdate.genericInformation.image[0].Image.push({
        ChannelType: format,
        Src: fileName,
        changed: true,
        file,
      });

      return;
    }

    this.exerciseToUpdate.genericInformation.videoImage.forEach(image => {
      if (image.ChannelType === format) {
        image.Src = fileName;
        image.changed = true;
        image.file = file;
      }
    });
  }

  public changeExplanationVideo(explanationVideo: ExplanationVideo[]): void {
    this.exerciseToUpdate.genericInformation.explanationVideo = explanationVideo;
  }

  public changeVideoPreview(id: string): void {
    this.exerciseToUpdate.genericInformation.videoPreview = id;
  }

  public changeAudioList(audioList: AudioPreview[]): void {
    this.exerciseToUpdate.languageInformation.audio = audioList;
  }

  public changeAudioPreview(audio: AudioPreview): void {
    this.exerciseToUpdate.languageInformation.audioPreview = audio;
  }

  public changeNameAndDescription(name: string, description: string): void {
    this.exerciseToUpdate.languageInformation.name = name;
    this.exerciseToUpdate.languageInformation.description = description;
  }

  /*
   * Save exercise *
   * The response Observable is typed with void because we dont use this response.
   * On success of this request (status code 200) we just reload the page to load
   * again the exercise list. If, for some reason, the response is going to be use,
   * it must be created a model for that.
   */
  public updateExercise(): Observable<void> {
    this.mapGenericInfoVideosRepetition();
    this.mapAudioListRepetition();

    this.exerciseEditBody = this.getExerciseEditBody();

    const upload = forkJoin([
      ...this.uploadImagesExercise(),
      ...this.uploadImagePreview(),
      ...this.uploadAudioExercise(),
      this.uploadAudioPreview(),
    ]);

    const updateExerciseRequest$ = this.http.put<void>(
      generatePaymentBackofficeEndpoint(BackEndpoints.Save),
      this.exerciseEditBody,
    );

    /** This makes the uploads request being before the save request. */
    return upload.pipe(
      concatMap(_ => updateExerciseRequest$),
    );
  }
}
