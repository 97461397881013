import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class GlobalLoadingService {

  private loadingCurrentState = new Subject<boolean>();


  get loading(): Subject<boolean> { return this.loadingCurrentState; }

  public setLoading(state: boolean): void {
    this.loadingCurrentState.next(state);
  }

}
