import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, Observable, Subject, tap } from 'rxjs';

import {
  CellType,
  EnrichedExercise,
  EnrichedExercisesFilter,
  EnrichedExercisesTable,
  EnrichedResponse,
  Environment,
  Language,
  TableRow,
} from '@models';
import { BackEndpoints, generatePaymentBackofficeEndpoint } from '@utils';

import { ExercisesEditService } from './exercises-edit.service';


@Injectable()
export class EnrichedExercisesService {

  private readonly enrichedExercisesTable$: Subject<EnrichedExercisesTable> = new Subject();

  private readonly videosPerPage = 15;

  private currentExercisesTable: EnrichedExercisesTable = {
    exercises: [],
    lastEvaluatedKey: '',
    hasMore: false,
  };

  get enrichedExercisesTable(): Subject<EnrichedExercisesTable> {
    return this.enrichedExercisesTable$;
  }


  constructor(
    private readonly http: HttpClient,
    private readonly exercisesEditService: ExercisesEditService,
  ) { }

  private getEnrichedTableRow = (exercise: EnrichedExercise): TableRow => [
    { type: CellType.Text, text: exercise.programName },
    { type: CellType.Text, text: exercise.environment },
    { type: CellType.Text, text: exercise.code },
    { type: CellType.Text, text: exercise.exerciseName },
    {
      type: CellType.Text,
      text: 'Edit',
      class: 'link-primary',
      action: () => {
        this.exercisesEditService.exerciseToEdit.next(exercise);

        const arg = { block: 'start', behavior: 'smooth' } as ScrollIntoViewOptions;

        setTimeout(() => document.getElementById('exercise-edit-scroll')?.scrollIntoView(arg));
      },
    },
  ];

  public cleanEnrichedExercisesTable(): void {
    this.currentExercisesTable = { exercises: [], lastEvaluatedKey: '', hasMore: false };
    this.enrichedExercisesTable$.next(this.currentExercisesTable);
  }

  public getEnrichedExercises(exercisesFilters: EnrichedExercisesFilter): Observable<EnrichedResponse> {
    return this.http.get<EnrichedResponse>(
      generatePaymentBackofficeEndpoint(BackEndpoints.EnrichedExercises),
      { params: { ...exercisesFilters, limit: this.videosPerPage } }
    );
  }

  public getEnrichedTable(exercisesFilters: EnrichedExercisesFilter = {}): Observable<EnrichedResponse> {
    return this.getEnrichedExercises(exercisesFilters).pipe(
      tap(enrichedResponse => {
        const mappedExercises = enrichedResponse.exercises.map(this.getEnrichedTableRow);

        this.currentExercisesTable = {
          ...enrichedResponse,
          exercises: [ ...this.currentExercisesTable?.exercises, ...mappedExercises ],
        };

        this.enrichedExercisesTable$.next(this.currentExercisesTable);
      }),
    );
  }

  public getExerciseByCodeTable(
    language: Language,
    environment: Environment,
    code: string,
  ): Observable<EnrichedExercise> {
    return this.http.get<EnrichedExercise>(
      generatePaymentBackofficeEndpoint(BackEndpoints.ExerciseByCode, code),
      { params: { language, environment } },
    ).pipe(
      catchError(({ error }) => {
        this.cleanEnrichedExercisesTable();

        throw error;
      }),
      tap(exercise => {
        const mappedExercise = this.getEnrichedTableRow(exercise);

        this.currentExercisesTable.exercises = [ mappedExercise ];

        this.enrichedExercisesTable$.next(this.currentExercisesTable);
      }),
    );
  }
}
