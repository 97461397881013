import { AbstractControl, FormGroup } from '@angular/forms';


export class FormUtil {

  static touchForm(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(control => {
      const controlField = formGroup.get(control) as AbstractControl;
      controlField.markAsTouched();
      controlField.markAsDirty();
      controlField.updateValueAndValidity();

      if (controlField instanceof FormGroup) {
        this.touchForm(controlField);
      }
    });
  }
}
