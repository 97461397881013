import { Component } from '@angular/core';

import { GlobalLoadingService } from '@services';


@Component({
  selector: 'app-global-loading',
  templateUrl: './global-loading.component.html',
  styleUrls: [ './global-loading.component.scss' ],
})
export class GlobalLoadingComponent {


  constructor(public readonly globalLoadingService: GlobalLoadingService) { }
}
