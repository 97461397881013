import { Component, Input } from '@angular/core';

import { ToastService } from '@services';


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styles: [ '.btn-close { width: 12px }' ],
})
export class ToastComponent {

  @Input() background = 'danger';
  @Input() closeBtn = false;


  constructor(public readonly toastService: ToastService) { }

}
