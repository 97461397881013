import { environment } from '@environment/environment';


/** All values MUST starts with a slash (/) */
export const enum BackEndpoints {
  // Authentication
  Login = '/login',

  // Exercises
  EnvsAndLanguages = '/exercise/environmentsAndLanguages',
  EnrichedExercises = '/exercise/listEnriched',
  ExerciseByCode = '/exercise/{{0}}',
  NonEnriched = '/exercise/listNonEnriched',
  PresignedUrl = '/exercise/generatePreSignedUrl',
  Save = '/exercise/save ',

  // Videos
  Videos = '/services/video',
}


export const isServicesUrl = (url: string): boolean => {
  return url.includes(environment.services.baseUrl);
};


const genericEndpointInterpolation = (
  baseurl: string,
  path: string,
  params: (string | number)[] = []
): string => {
  const interpoled = params.reduce<string>((old, curr, i) => {
    return old.split(`{{${i}}}`).join(curr.toString());
  }, path);

  return baseurl + interpoled;
};

export const generateBackofficeEndpoint = (
  path: BackEndpoints,
  ...params: (string | number)[]
): string => {
  return genericEndpointInterpolation(environment.services.baseUrl, path, params);
};

export const generatePaymentBackofficeEndpoint = (
  path: BackEndpoints,
  ...params: (string | number)[]
): string => {
  return genericEndpointInterpolation(`${environment.services.baseUrl}/payment/backoffice`, path, params);
};
