import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuardCanActivate } from '@guards';


/**
 * This object contains all legacy routes with their redirects to this project routes.
 * This was created to avoid creating routes with the same name as the legacy project,
 * creating routes with kebab case names, instead of cammel case names, allowing to rename
 * the routes.
 */
const legacyRoutes: Routes = [
  { path: 'CheckEnrichedExercise', redirectTo: 'enrichment-report' },
  { path: 'EnrichedExercise', redirectTo: 'exercises' },
  {
    /**
     * This route only exists so that the legacy project (Angular Js project) can redirect
     * when the logout button is triggered.
     */
    path: 'Account/Logoff', redirectTo: 'login',
  },
];

const routes: Routes = [
  ...legacyRoutes,
  {
    path: '',
    canActivate: [ authGuardCanActivate ],
    children: [
      {
        path: 'enrichment-report',
        loadChildren: () => import('./views/enrichment-report/enrichment-report.module').then(m => m.EnrichmentReportModule),
      },
      {
        path: 'exercises',
        loadChildren: () => import('./views/exercises/exercises.module').then(m => m.ExercisesModule),
      },
    ],
  },
  {
    path: 'login',
    data: { hideSidebar: true },
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule),
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }
