import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, Observable, tap } from 'rxjs';

import { LoginRequestCredentials, LoginResponse } from '@models';
import { AppConstants, BackEndpoints, generatePaymentBackofficeEndpoint } from '@utils';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly loginEmitter = new EventEmitter<boolean>();

  public get loginEmitterListener(): EventEmitter<boolean> {
    return this.loginEmitter;
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
  ) { }

  private saveToken(userToken: string): void {
    localStorage.setItem(AppConstants.STOR_APP_USER_TOKEN, userToken);
  }

  public getUserToken(): string | null {
    return localStorage.getItem(AppConstants.STOR_APP_USER_TOKEN);
  }

  public authenticateUser(loginCredentials: LoginRequestCredentials): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(
      generatePaymentBackofficeEndpoint(BackEndpoints.Login),
      loginCredentials,
    ).pipe(
      tap(loginResponse => {
        const userToken = loginResponse['X-BTLive-Authorization-User'];

        this.saveToken(userToken);
        this.router.navigate([ '/' ]);

        this.loginEmitter.emit(true);
      }),
      catchError(httpError => {
        throw httpError.error;
      }),
    );
  }

  public checkAuth(): boolean {
    return !!this.getUserToken();
  }

  public logout(): void {
    localStorage.removeItem(AppConstants.STOR_APP_USER_TOKEN);

    this.loginEmitter.emit(false);
    this.router.navigate([ '/login' ]);
  }
}
