import { TableRow } from './generic-table.model';
import { Environment, Language } from './response';


export interface EnrichedExercisesSearchFilter {
  environment: Environment;
  language: Language;
}

export interface EnrichedExercisesTable {
  exercises: TableRow[];
  lastEvaluatedKey: string;
  hasMore: boolean;
}
