export enum ImagesFormat {
  IMAGE_WEB = 'IMAGE_WEB',
  IMAGE_WEB_MOBILE = 'IMAGE_WEB_MOBILE',
  ANDROID_MDP = 'ANDROID_MDP',
  ANDROID_HDPI = 'ANDROID_HDPI',
  ANDROID_XHDPI = 'ANDROID_XHDPI',
  ANDROID_XXHDPI = 'ANDROID_XXHDPI',
  IOS_IPDAD = 'IOS_IPDAD',
  IOS_IPHONE = 'IOS_IPHONE',
}
