import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GlobalLoadingComponent } from './global-loading.component';


@NgModule({
  declarations: [ GlobalLoadingComponent ],
  imports: [ CommonModule ],
  exports: [ GlobalLoadingComponent ],
})
export class GlobalLoadingModule { }
