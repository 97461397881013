export enum Environment {
  Default = 'Default',
  Gym = 'Gym',
  Home = 'Home',
  Outdoor = 'Outdoor',
}

export enum ShortLanguage {
  Portuguese = 'ptBR',
  English = 'enUS',
}

export type Language = 'Portuguese' | 'English';

export interface EnvsAndLanguagesResponse {
  languages: Language[];
  environments: Environment[];
}
