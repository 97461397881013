import { ImagesFormat } from '@models';

import { Environment } from './envs-languages-response.model';


export interface FileUpload {
  /** Content to upload at s3. */
  file?: File;
  /** Flag to control when the object is changed by the user, so we can know what object we must do an upload to S3. */
  changed?: boolean;
}

/* Enriched */
export interface AudioPreview extends FileUpload {
  Description: string;
  Repetition: number;
  AudioID: string;
}

export interface ExplanationVideo {
  VideoId: string;
  Repetition: number;
}

export interface VideoImage extends FileUpload {
  Src: string;
  ChannelType: ImagesFormat;
}

export interface Image {
  Order: number;
  Image: VideoImage[];
}

export interface LanguageInformation {
  name: string;
  description: string;
  audioPreview: AudioPreview | null;
  audio: AudioPreview[] | null;
}

export interface GenericInformation {
  videoPreview: string;
  videoImage: VideoImage[];
  image: Image[];
  explanationVideo: ExplanationVideo[] | null;
}

export interface EnrichedExercise {
  languageInformation: LanguageInformation;
  environment: Environment;
  exerciseName: string;
  programId: string;
  programName: string;
  code: string;
  genericInformation: GenericInformation;
}

export interface EnrichedResponse {
  exercises: EnrichedExercise[];
  lastEvaluatedKey: string;
  hasMore: boolean;
}

/* NonEnriched */
export interface NonEnrichedExercise {
  environment: Environment;
  exerciseName: string;
  nonEnrichedLanguages: string[];
  program: string;
  code: string;
}

export interface NonEnrichedResponse {
  exercises: NonEnrichedExercise[];
}
