import { DateFilter } from '@models';


export class AppConstants {
  public static STOR_APP_USER_TOKEN = 'app-user-token';

  public static DATE_FILTER_OPTIONS: DateFilter[] = [
    { label: 'All', value: 0 },
    { label: '30 days', value: 30 },
    { label: '60 days', value: 60 },
    { label: '90 days', value: 90 },
  ];
}
