import { DateFilterPeriods } from '@models';

/**
 * Function to dates within a determined period
 * @param {number} numberDays - With number days of period
 * @returns {DateFilterPeriods} With the object with start and end dates in a miliseconds as number
 */
export const getStartAndEndDate = (
  numberDays: number,
): DateFilterPeriods => {
  const today = new Date();

  const dates = [ numberDays ].map(i => {
    const day = new Date(today);
    day.setDate(day.getDate() - i);
    return day;
  });

  return {
    startDate: dates[0].getTime(),
    endDate: today.getTime(),
  };
};
