import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { environment } from '@environment/environment';
import { SidebarLinkModel } from '@models';
import { AuthService } from '@services';


@Component({
  selector: 'app-sidebar-menu-item',
  templateUrl: './sidebar-menu-item.component.html',
  styleUrls: [ './sidebar-menu-item.component.scss' ],
})
export class SidebarMenuItemComponent implements OnInit, OnDestroy {

  /** Used to control the collapse event. Must be unique. */
  @Input() identifier!: string;
  /**
   *  Flag to indicates the links will redirect to a internal route (false) or to the AngularJs
   *  project (true).
   */
  @Input() redirectToLegacy = true;
  @Input() header!: SidebarLinkModel;
  @Input() set links(list: SidebarLinkModel[]) {
    this.formattedLinks = list;
    this.originalLinks = list;

    if (this.authService.checkAuth()) {
      this.formattedLinks = this.getFormattedLinks();
    }
  }

  private loginSubscription!: Subscription;
  private originalLinks!: SidebarLinkModel[];

  public formattedLinks!: SidebarLinkModel[];


  constructor(private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.loginSubscription = this.authService.loginEmitterListener.subscribe(authenticated => {
      if (authenticated && this.redirectToLegacy) {
        this.formattedLinks = this.getFormattedLinks();
      }
    });
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }

  private getFormattedLinks(): SidebarLinkModel[] {
    return this.originalLinks.map(item => ({
      ...item,
      redirectTo: this.getLegacyUrlWithToken(item.redirectTo as string),
    }));
  }

  private getLegacyUrlWithToken(route: string): string {
    if (!this.redirectToLegacy) {
      return route;
    }

    const param = `?id=${this.authService.getUserToken()}`;

    return environment.legacyFrontUrl + route + param;
  }

}
