import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthService } from '@services';


export const authGuardCanActivate: CanActivateFn = () => {
  const authService = inject(AuthService);
  const loggedIn = authService.checkAuth();

  if (!loggedIn) {
    authService.logout();
  }

  return loggedIn;
};
