import { FormControl } from '@angular/forms';


export enum CellType {
  Icon,
  Text,
  Input,
}

export interface TableCell {
  action?: () => void;

  /** Classes that will be applied to the text or the icon. */
  class?: string;

  /**
   * Font awesome icon, should be passed just the name of the icon
   * @link https://fontawesome.com/v4/icons
   * @example
   * If the icon is the `fa-trash`, this attribute must be only `icon: 'trash'`.
   */
  icon?: string;
  text?: string;
  input?: {
    control: FormControl<string | null>;
    placeholder?: string;
  }
  type: CellType;
}

export interface TableHeader extends Omit<TableCell, 'type' | 'icon'> {
  /**
   * Defines the number of cells that column will have.
   * @default 1.
   */
  columnSize: number;

  /**
   * Defines if the column can be sorted alphabetically.
   * @default false
   */
  sortable?: boolean;

  /** Control attribute, indicates that the column have already sorted one time. */
  sorted?: boolean;
}

export type TableRow = TableCell[];
