import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ToastService {

  private toastMessage = '';
  private showToast = new Subject<boolean>();

  get show(): Subject<boolean> { return this.showToast; }

  set message(content: string) { this.toastMessage = content; }
  get message(): string { return this.toastMessage; }

}
