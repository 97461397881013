import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, Observable } from 'rxjs';

import { CellType, NonEnrichedExercise, NonEnrichedResponse, TableRow } from '@models';
import { BackEndpoints, generatePaymentBackofficeEndpoint, sortExercisesByCode } from '@utils';


@Injectable()
export class NonEnrichedExercisesService {


  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) { }


  private getNonEnrichedTableRow = (exercise: NonEnrichedExercise): TableRow => {
    return [
      { type: CellType.Text, text: exercise.program },
      { type: CellType.Text, text: exercise.environment},
      { type: CellType.Text, text: exercise.code },
      { type: CellType.Text, text: exercise.exerciseName },
      {
        type: CellType.Text,
        text: exercise.nonEnrichedLanguages[0],
        class: 'link-primary text-decoration-underline text-center',
        action: () => this.router.navigate([ 'exercises', exercise.code ]),
      },
    ];
  };

  public getNonEnrichedExercises(): Observable<NonEnrichedExercise[]> {
    return this.http.get<NonEnrichedResponse>(
      generatePaymentBackofficeEndpoint(BackEndpoints.NonEnriched),
    ).pipe(
      map(response => {
        return response.exercises
          .filter(exercise => exercise.nonEnrichedLanguages.length)
          .sort(sortExercisesByCode);
      }),
    );
  }

  public getNonEnrichedTable(): Observable<TableRow[]> {
    return this.getNonEnrichedExercises().pipe(
      map(exercises => exercises.map(this.getNonEnrichedTableRow)),
    );
  }
}
