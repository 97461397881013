<div
  *ngIf="toastService.show | async"
  class="alert alert-{{ background }} alert-dismissible fade show"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div id="toast-message" [innerHTML]="toastService.message"></div>

  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="alert"
    aria-label="Close"
    [hidden]="!closeBtn"
  ></button>
</div>
