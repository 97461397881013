import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject, tap } from 'rxjs';

import { CellType, TableRow, Video, VideosFilter, VideosResponse, VideosTable } from '@models';
import { BackEndpoints, generateBackofficeEndpoint } from '@utils';


@Injectable()
export class VideosService {

  get selectedVideo(): Subject<Video> { return this.selected$; }

  get videosTable(): Subject<VideosTable> { return this.videosTable$; }

  private currentVideosTable: VideosTable = {
    videos: [],
    last_key: '',
    has_more: false,
  };

  private readonly videosTable$: Subject<VideosTable> = new Subject();
  private readonly selected$: Subject<Video> = new Subject();


  constructor(private readonly http: HttpClient) { }

  public getVideosTable(videosFilter: VideosFilter): Observable<VideosResponse> {
    return this._getVideos(videosFilter).pipe(
      tap(videosResponse => {
        const mappedVideos = videosResponse.videos.map(this._getVideoTableRow);

        this.currentVideosTable = {
          ...videosResponse,
          videos: [ ...this.currentVideosTable?.videos, ...mappedVideos ],
          last_key: videosResponse.videos[videosResponse.videos.length - 1]?.id,
        };

        this.videosTable$.next(this.currentVideosTable);
      })
    );
  }

  public cleanEnrichedExercisesTable(): void {
    this.currentVideosTable = { videos: [], last_key: '', has_more: true };
    this.videosTable$.next(this.currentVideosTable);
  }

  private _getVideos(videosFilter: VideosFilter): Observable<VideosResponse> {
    return this.http.get<VideosResponse>(
      generateBackofficeEndpoint(BackEndpoints.Videos),
      { params: { ...videosFilter } },
    );
  }

  private _getVideoTableRow = (video: Video): TableRow => {
    return [
      {
        type: CellType.Text,
        text: video.metadata.description,
        class: 'link-primary',
        action: () => this.selected$.next(video),
      },
      { type: CellType.Text, text: video.associatedTo.toString() },
      {
        type: CellType.Text,
        text: formatDate(new Date(video.creationDate), 'dd/MM/yyyy hh:mm:ss a', 'pt' ),
      },
    ];
  };
}
