export interface Video {
  associatedTo: number;
  fileName: string;
  metadata: {
    description: string;
    language: number;
    category: number;
  };
  urls: {
    SizeVideoHD: number;
    UrlVideoHD: string;
    UrlVideoStreaming: string;
    SizeVideoSD: number;
    UrlVideoSD: string;
  },
  createdBy: string;
  encodingJobId: number;
  id: string;
  creationDate: number;
  lastStatus: number;
  status: string;
}

export interface VideosResponse {
  videos: Video[];
  last_key: string;
  has_more: boolean;
}
