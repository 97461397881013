import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AuthService } from '@services';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, OnDestroy {

  private userLoggedListenerSub!: Subscription;

  public isUserLogged = false;


  constructor(private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.isUserLogged = this.authService.checkAuth();
    this.userLoggedListenerSub = this.authService.loginEmitterListener.subscribe(isLogged => {
      this.isUserLogged = isLogged;
    });
  }

  ngOnDestroy(): void {
    this.userLoggedListenerSub?.unsubscribe();
  }

  public doLogout(): void {
    this.authService.logout();
  }
}
