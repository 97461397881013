<nav class="navbar navbar-dark bg-dark">
  <div class="container-fluid mx-3">
    <img
      alt=""
      class="navbar-brand"
      src="assets/images/logo_btfit.png"
      aria-hidden="true"
    >

    <div *ngIf="isUserLogged" class="ml-auto">
      <div class="d-flex text-secondary">
        Welcome Admin!
        <br>

        <div
          id="logout-button"
          class="mx-3"
          role="button"
          tabindex="0"
          (click)="doLogout()"
          (keydown)="doLogout()"
        >
          Log Off
        </div>

      </div>
    </div>
  </div>
</nav>
