import { VideoImage } from '@models';

import { AudioPreview, Environment, ExplanationVideo, Image, Language, ShortLanguage } from '../response';


export interface EnrichedExercisesFilter {
  lastEvaluatedKey?: string;
  language?: Language;
}

export interface DetailByLanguage {
  Language: ShortLanguage;
  Name: string;
  Description: string;
  /**
   * The AudioPreview and AudiosExercise attributes must be duplicate in the ExerciseEditBody.
   * Since the service only works this way, because of an implementation at it.
   */
  AudioPreview: AudioPreview | null;
  AudiosExercise: AudioPreview[] | null;
}

export interface ExerciseEditBody {
  VideosExercise: ExplanationVideo[] | null;
  ImagesExercise: Image[];
  VideoPreviewId: string;
  ImagePreview: VideoImage[];
  AudioPreview: AudioPreview | null;
  AudiosExercise: AudioPreview[] | null;
  EnrichedExerciseDetailByLanguage: DetailByLanguage[];
  Environment: Environment;
  ExerciseId: string;
  ExerciseType: number;
  ProgramId: string;
}
