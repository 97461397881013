import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { S3FileContent, S3PresinedUrlResponse } from '@models';
import { BackEndpoints, generatePaymentBackofficeEndpoint } from '@utils';


@Injectable()
export class S3Service {


  constructor(private readonly http: HttpClient) { }

  public getPresignedUrl(
    fileName: string,
    fileContent: S3FileContent = S3FileContent.PNG,
  ): Observable<S3PresinedUrlResponse> {
    return this.http.get<S3PresinedUrlResponse>(
      generatePaymentBackofficeEndpoint(BackEndpoints.PresignedUrl),
      { params: { fileName, fileContent } },
    );
  }

  public uploadFile(presignedUrl: string, file: File): Observable<void> {
    const headers = {
      'Content-type': file.type,
    };

    return this.http.put<void>(presignedUrl, file, { headers });
  }
}
