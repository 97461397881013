import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '@services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly logoutUrl = '/Account/Logoff';

  private routeSubscription!: Subscription;

  public hideSidebar = true;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.router.events.subscribe(data => {
      if (data instanceof RoutesRecognized) {
        this.hideSidebar = data.state.root.firstChild?.data['hideSidebar'];
      } else if (data instanceof NavigationEnd && data.url === this.logoutUrl) {
        this.authService.logout();
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }
}
