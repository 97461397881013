<nav
  id="sidebar-menu"
  class="bg-white shadow px-3 pt-4 pb-6 h-100 overflow-y-auto"
>
  <h3 class="mb-3">Administration Area</h3>

  <a href="/" class="link-btn border rounded bg-light text-dark">
    <i class="fa fa-home fa-fw fa-lg me-2 align-self-center" aria-hidden="true"></i>
    <span class="fw-bolder">Home</span>
  </a>

  <app-sidebar-menu-item
    identifier="manage-contents"
    [redirectToLegacy]="false"
    [header]="{ label: 'Manage contents', icon: 'list-alt', active: false }"
    [links]="[
      { label: 'Generic content', icon: 'file-text', redirectTo: '/Area' },
      { label: 'Parameter',       icon: 'list',      redirectTo: '/Parameter' },
      { label: 'Data migration',  icon: 'list',      redirectTo: '/Migration' }
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-videos"
    [header]="{ label: 'Manage videos', icon: 'video-camera', active: true }"
    [links]="[
      { label: 'Video upload',  icon: 'cloud-upload', redirectTo: '/videos/new' },
      { label: 'List videos',   icon: 'th-list',      redirectTo: '/videos/list'},
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-grooup-classes"
    [header]="{ label: 'Manage group classes', icon: 'users', active: true }"
    [links]="[
      { label: 'Register new group class',      icon: 'plus',     redirectTo: '/sessions/new' },
      { label: 'List registered group classes', icon: 'th-list',  redirectTo: '/sessions/list' },
      { label: 'Manage schedule',               icon: 'calendar', redirectTo: '/sessions/schedule' },
      { label: 'Register new playlist',         icon: 'plus',     redirectTo: '/activityPlaylist/new' },
      { label: 'List new playlist',             icon: 'th-list',  redirectTo: '/activityPlaylist/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-programs"
    [redirectToLegacy]="false"
    [header]="{ label: 'Manage programs', icon: 'th-large', active: false }"
    [links]="[
      { label: 'Programs outreach', icon: 'gift', redirectTo: '/Program' },
      { label: 'Anamnese',          icon: 'gift', redirectTo: '/Anamnese?language=0&programId=1' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-exercises"
    [redirectToLegacy]="false"
    [header]="{ label: 'Manage exercises', icon: 'th-large', active: true }"
    [links]="[
      { label: 'Exercices',         icon: 'file-text-o', redirectTo: '/EnrichedExercise' },
      { label: 'Enrichment Report', icon: 'file-text-o', redirectTo: '/CheckEnrichedExercise' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-equipment"
    [redirectToLegacy]="false"
    [header]="{ label: 'Manage equipment', icon: 'th-large', active: false }"
    [links]="[
      { label: 'Enrichment Equipment', icon: 'gift', redirectTo: '/EnrichedEquipment' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-finances"
    [redirectToLegacy]="false"
    [header]="{ label: 'Manage finances', icon: 'th-large', active: false }"
    [links]="[
      { label: 'File upload',     icon: 'cloud-upload', redirectTo: '/ExportOrder/Upload' },
      { label: 'Export Shopping', icon: 'gift',         redirectTo: '/ExportOrder' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-partners"
    [header]="{ label: 'Manage partners', icon: 'th-large', active: true }"
    [links]="[
      { label: 'New partner',   icon: 'plus',    redirectTo: '/partners/new' },
      { label: 'List partners', icon: 'th-list', redirectTo: '/partners/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-coupons"
    [header]="{ label: 'Manage coupons', icon: 'ticket', active: true }"
    [links]="[
      { label: 'New coupon',   icon: 'plus',    redirectTo: '/coupons/new' },
      { label: 'List coupons', icon: 'th-list', redirectTo: '/coupons/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-banners"
    [header]="{ label: 'Manage banners', icon: 'tags', active: true }"
    [links]="[
      { label: 'New banner',   icon: 'plus',    redirectTo: '/banners/new' },
      { label: 'List banners', icon: 'th-list', redirectTo: '/banners/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-advertisement"
    [header]="{ label: 'Manage advertisement', icon: 'bullseye', active: true }"
    [links]="[
      { label: 'New advertisement',   icon: 'plus',    redirectTo: '/advertising/new' },
      { label: 'List advertisements', icon: 'th-list', redirectTo: '/advertising/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-presenters"
    [header]="{ label: 'Manage presenters', icon: 'user', active: true }"
    [links]="[
      { label: 'New presenter',   icon: 'plus',    redirectTo: '/presenters/new' },
      { label: 'List presenters', icon: 'th-list', redirectTo: '/presenters/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-activities"
    [header]="{ label: 'Manage activities', icon: 'child', active: true }"
    [links]="[
      { label: 'New activity',       icon: 'plus',    redirectTo: '/activities/new' },
      { label: 'New pilot activity', icon: 'flask',   redirectTo: '/activities/pilot/new' },
      { label: 'List activities',    icon: 'th-list', redirectTo: '/activities/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-challenges"
    [header]="{ label: 'Manage challenges', icon: 'gamepad', active: true }"
    [links]="[
      { label: 'New challenge',    icon: 'plus',    redirectTo: '/challenges/new' },
      { label: 'List challenges', icon: 'th-list', redirectTo: '/challenges/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-training-programs"
    [header]="{ label: 'Manage Training Programs', icon: 'cube', active: true }"
    [links]="[
      { label: 'New Training Program',   icon: 'plus',    redirectTo: '/trainingProgram/new' },
      { label: 'List Training Programs', icon: 'th-list', redirectTo: '/trainingProgram/list' },
    ]"
  ></app-sidebar-menu-item>

  <app-sidebar-menu-item
    identifier="manage-tvos-additional-contents"
    [header]="{ label: 'Manage TvOs Additional Contents', icon: 'cube', active: true }"
    [links]="[
      { label: 'New Additional Content',   icon: 'plus',    redirectTo: '/additionalcontent/new' },
      { label: 'List Additional Contents', icon: 'th-list', redirectTo: '/additionalcontent/list' },
      { label: 'Reorder Card Position',    icon: 'sort',    redirectTo: '/additionalcontent/getactives' },
    ]"
  ></app-sidebar-menu-item>
</nav>
