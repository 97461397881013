<button
  class="btn link-btn border bg-light rounded-0 rounded-top mt-2"
  type="button"
  data-bs-toggle="collapse"
  aria-expanded="false"
  [attr.data-bs-target]="'#' + identifier"
  [attr.aria-controls]="identifier"
  [disabled]="!header.active"
>
  <i class="fa fa-fw fa-{{header.icon}} me-2" aria-hidden="true"></i>
  <span class="fw-bolder">{{ header.label }}</span>
</button>

<div [id]="identifier" class="collapse" data-bs-parent="#sidebar-menu">
  <ng-container *ngFor="let link of formattedLinks">
    <a
      *ngIf="redirectToLegacy; else internalRoute"
      class="link-btn border border-top-0"
      [href]="link.redirectTo"
    >
      <ng-container
        *ngTemplateOutlet="
          linkContent;
          context: { icon: link?.icon, title: link?.label }
        "
      ></ng-container>
    </a>

    <ng-template #internalRoute>
      <a
        class="link-btn border border-top-0"
        [routerLink]="[ link.redirectTo ]"
        routerLinkActive="active"
      >
        <ng-container
          *ngTemplateOutlet="
            linkContent;
            context: { icon: link?.icon, title: link?.label }
          "
        ></ng-container>
      </a>
    </ng-template>
  </ng-container>
</div>

<ng-template #linkContent let-icon="icon" let-title="title">
  <i class="fa fa-fw fa-{{icon}} small me-2" aria-hidden="true"></i>
  <span class="fs-6">{{ title }}</span>
</ng-template>
