import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Environment, EnvsAndLanguagesResponse, Language } from '@models';
import { BackEndpoints, generatePaymentBackofficeEndpoint } from '@utils';


@Injectable()
export class EnvsLanguagesService {

  constructor(private readonly http: HttpClient) { }

  /**
   * In views that use both environments ang languages inputs use this method instead making two
   * requests with the methods below.
   */
  public getEnvsAndLanguages(): Observable<EnvsAndLanguagesResponse> {
    return this.http.get<EnvsAndLanguagesResponse>(
      generatePaymentBackofficeEndpoint(BackEndpoints.EnvsAndLanguages),
    );
  }

  public getEnvironments(): Observable<Environment[]> {
    return this.getEnvsAndLanguages().pipe(
      map(response => response.environments),
    );
  }

  public getLanguages(): Observable<Language[]> {
    return this.getEnvsAndLanguages().pipe(
      map(response => response.languages)
    );
  }
}
