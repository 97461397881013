import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { GlobalLoadingModule } from '@components/global-loading/global-loading.module';
import { NavbarModule } from '@components/navbar/navbar.module';
import { SidebarMenuModule } from '@components/sidebar-menu/sidebar-menu.module';
import { ToastModule } from '@components/toast/toast.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';


registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    GlobalLoadingModule,
    HttpClientModule,
    NavbarModule,
    SidebarMenuModule,
    ToastModule,
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'pt',
    },
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
