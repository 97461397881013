<app-navbar></app-navbar>

<div class="d-flex flex-column flex-md-row">
  <app-sidebar-menu class="content-height" [hidden]="hideSidebar"></app-sidebar-menu>

  <main class="content-height overflow-y-auto flex-fill py-4 px-5">
    <app-toast></app-toast>

    <div class="bg-white shadow">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<app-global-loading></app-global-loading>
