/** This file is used to development and preproduction environments. */
export const environment = Object.freeze({
  production: false,

  legacyFrontUrl: 'https://backofficepreprod.bt.fit/#',

  services: {
    baseUrl: 'https://servicespreprod.bt.fit',
  },

  /** Used to access medias through cloudfront, as images, audios, videos, etc. */
  mediaUrl: 'https://imagesfitnesspreprodir.btfit.com.br',
});
